.plusPageContainer {
  flex: 1;
  overflow: auto;
  padding: 2rem;
  padding-top: 0;
  font-weight: 900;
  position: relative;
  margin: 1rem 0;
}

.plusPageContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Comic Sans', sans-serif;
  color: white;
  font-weight: 900;
}

.plusPageContainer button {
  background-color: #5252cc;
  font-size: 1.6rem;
  margin-bottom: 0.15rem;
  width: 100%;
  border: none;
  border-radius: 0.5rem;
  padding: 0.8rem;
  cursor: pointer;
  color: #ddebf7;
  border: 3px solid black;
}

.plusPageContainer .accentBtn {
  color: white;
  background-color: #4122fc;
}

.plusPageContainer .blackBtn {
  color: #47fd54;
  background-color: black;
}
