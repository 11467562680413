.container {
  width: 65%;
  height: calc(100vh - 356px);
  margin: 0 auto;
  max-width: 1800px;
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 30px;
  position: relative;
  margin: 0 12px;
  scrollbar-width: none;
}

.container.active {
  overflow: hidden;
}

.floatingComments {
  background-color: white;
  border: 2px solid black;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: -10px;
  max-height: 0%;
  overflow: auto;
  transition: max-height .3s ease;
}

.floatingComments.active {
  max-height: 100%;
}

.floatingComments>:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 10px;
  background-color: white;
  z-index: 2000;
}

.floatingComments>:nth-child(1)>button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.floatingComments>:nth-child(1)>span {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-size: 14px;
}

.scoreBatMainContainer {
  display: flex;
}

.scoreBatMenuContainer {
  background-color: #fbf9f9;
  padding: 20px 5px 0px 5px;
  width: 20%;
  position: relative;
}

.stickyContainer {
  position: sticky;
  top: 25px;
}

.scoreBatButton {
  width: 100%;
  background-color: transparent;
  border: none;
  font-weight: bold;
  margin: 5px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.scoreBatButton>span {
  font-size: 12px;
  text-align: left;
}

.scorebatTopMenu {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  padding: 10px 0px 20px 0px;
  background-color: white;
  position: sticky;
  top: 0px;
}

.topMenuBtnContainers {
  width: 100%;
}

.topMenuBtnContainers>button {
  width: 100%;
  background-color: #07041f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 24px;
  font-weight: 600;
  padding: 20px 10px;
  border-radius: 10px;
  height: 100%;
  border: none;
  transition: all .15s ease;
  cursor: pointer;
}

@media (max-width: 1420px) {
  .topMenuBtnContainers>button {
    font-size: 22px;
  }
}

.whiteCircleBackground {
  background-color: white;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
}

.allCommentsLoaderWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.allCommentsLoaderWrapper div {
  align-items: flex-start !important;
  padding-top: 2rem;
}