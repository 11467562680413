.shopHomePage * {
  margin: 0;
  padding: 0;
}

.shopHomePage {
  width: 100% !important;
  height: 100%;
  overflow-y: scroll;
  padding: 0.725rem;
  padding-bottom: 2rem;
  padding-top: 0;
  height: calc(100vh - 180.6px);
  scrollbar-width: none;
}

.featuredProducts {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  justify-items: space-between;
  text-align: center;
  gap: 5px;
}

.featuredProducts h2 {
  font-size: 1rem;
  margin: 5px;
}

.featuredShop,
.featuredAuctions,
.featuredSportTurist {
  width: 33%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.featuredShop {
  color: #ff0000;
}

.featuredAuctions {
  color: #ffc000;
}

.featuredSportTurist {
  color: #38f81e;
}

.sectionsMenu {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  margin-block: 20px;
}

.sectionsMenu h2 {
  font-size: 1.725rem;
}

.shopSection,
.auctionsSection,
.sportTouristSection {
  display: flex;
  gap: 10px;
  flex-direction: column;
  border: 2px solid;
  border-radius: 1rem;
  padding: 0.3rem;
}

.sectionsMenu .sectionButtonsWrapper {
  display: flex;
  gap: 3px;
}

.sectionsMenu .sectionButtonsWrapper button {
  border: none;
  color: #0B0B25;
  border-radius: 0.5rem;
  flex: 1;
  min-height: 40px;
  border: none;
  cursor: pointer;
}

.shopSection {
  border-color: #ff0000;
  color: #ff0000;
}

.shopSection button {
  background-color: #ff0000;
  color: #0B0B25;
}

.auctionsSection {
  border-color: #ffc000;
  color: #ffc000;
}

.auctionsSection button {
  background-color: #ffc000;
}

.sportTouristSection {
  border-color: #b2ec0a;
  color: #b2ec0a;
}

.sportTouristSection button {
  background-color: #b2ec0a;
}

@media (min-width: 765px) {
  .shopHomePage {
    padding-bottom: 0rem;
    height: calc(100vh - 600px) !important;
  }

  .featuredProducts h2 {
    font-size: 1.5rem;
  }
}
