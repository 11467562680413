.newsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  border: 1px solid #000;
  height: 62px;
  background-color: #0a0d23;
  color: white;
  margin: 1rem ;
  font-family: 'Comic Sans', sans-serif;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 900;
  position: relative;
  z-index: 1000; 
}

.sideTitle {
  font-size: 24px;
  font-weight: bold;
  z-index: 10;
  overflow: hidden;
  background: linear-gradient(to bottom, #e30000, #7a0303);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  flex-shrink: 0;
  cursor: pointer;
}

.sideTitleRight {
 position: absolute;
 right: 0;
 top: 0;
 height: 100%;
}

.newsContent {
  border: #0a0d23;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0;
  position: relative;
  animation: scroll linear infinite;
}

.newsItem {
  display: flex;
  align-items: center;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}
.redStartIcon {
  fill: red;
  color: red;
  padding: 0 1rem;
  height: 3rem;
  width: 3rem;
}
