.mainCenter {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.loginModal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 460px !important;
}

.ant-modal-content {
  padding: 0 !important;
  display: none !important;
}

.logInContainer {
  text-align: center;
  margin: 10px 0px;
}

.logInContainer > span {
  font-size: 20px;
  font-weight: 700;
  /* font-family: 'Comic Sans', sans-serif; */
  line-height: normal;
  text-transform: uppercase;
}

.leaveCommentHeader {
  box-sizing: border-box;
  position: relative;
  padding-bottom: 10px;
}

.textArea {
  width: 100%;
  height: 100%;
  display: flex;
  resize: none;
  box-sizing: border-box;
  outline: none;
  padding: 10px;
  border: 2px solid gray;
  border-radius: 6px;
  font-size: 20px;
}

.textArea:focus + .placeholder {
  opacity: 0;
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 1;
  color: gray;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.15s ease;
  text-transform: uppercase;
  font-family: 'Comic Sans', sans-serif;
}

.leaveCommentButtonContainer {
  width: 100%;
}

.leaveCommentButtonContainer > button {
  width: 100%;
  background-color: rgb(0, 0, 90);
  color: white;
  font-weight: 700;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  font-family: 'Comic Sans', sans-serif;
  padding: 5px;
  margin-top: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.leaveCommentButtonContainer > button:hover {
  background-color: rgb(0, 0, 60);
}

.comment {
  margin: 5px 0px;
  display: flex;
  flex-direction: column;
  font-weight: 700;
}

.top {
  display: flex;
  gap: 10px;
}

.top.reverse {
  flex-direction: row-reverse;
}

.bottom {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bottom.reverse {
  flex-direction: row-reverse;
}

.loginContainerModal,
.registerContainerModal {
  background-color: white;
  border-radius: 10px;
  max-width: 300px;
  width: 100%;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: 2000;
  transition: all 0.3s ease;
}

.loginContainerModal.active,
.registerContainerModal.active {
  visibility: visible;
  pointer-events: all;
  opacity: 1;
}

.loginRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.loginRowInfo {
  background-color: rgb(0, 0, 100);
  color: white;
  border-radius: 10px;
  font-family: 'Questrial', sans-serif;
}

.loginRowInfo > p {
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
}

.loginRow > p {
  margin: 0;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.logovanje > p {
  color: rgb(0, 0, 100);
  font-size: 30px;
}

.loginFormRow > input {
  outline: none;
  background-color: rgb(229, 229, 229);
  border: none;
  border-radius: 4px;
  font-family: 'Questrial', sans-serif;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 1rem;
}

.forgotPass {
  text-align: center;
}

.forgotPass > button {
  color: rgb(0, 0, 100);
  font-family: 'Questrial', sans-serif;
  background: transparent;
  border: none;
  font-weight: bold;
  font-size: 14px;
  width: max-content;
  cursor: pointer;
}

.loginButton > button {
  background-color: rgb(0, 0, 100);
  color: white;
  border: none;
  border-radius: 6px;
  padding: 7px 10px;
  font-family: 'Questrial', sans-serif;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
}

.registrujSe {
  text-align: center;
}

.registrujSe > span {
  font-size: 14px;
}

.registrujSe > button {
  background-color: transparent;
  border: none;
  color: #573b8a;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Questrial', sans-serif;
  cursor: pointer;
}

.loginOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: block;
  visibility: hidden;
  pointer-events: none;
  z-index: 1999;
}

.loginOverlay.active {
  visibility: visible;
  pointer-events: all;
}

.labelChk {
  font-size: 14px;
}

.errorRegDiv {
  color: red;
  font-size: 16px;
}

.ratingsContainer {
  justify-content: space-between;
  gap: 2px !important;
  flex-wrap: wrap;
}
