.mainCenter {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 65%;
  text-align: left;
  overflow: auto;
  margin-bottom: 40px;
}

.container {
  overflow: auto;
}

.red {
  color: darkblue;
}

.main {
  width: 350px;
  height: 650px;
  background: linear-gradient(#070044, #080808);
  overflow: hidden;
  border-radius: 8px;
}

.signup {
  position: relative;
  height: 650px;
}

.label {
  color: #fff;
  font-size: 2.3em;
  justify-content: center;
  display: flex;
  margin: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.labelChk {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  box-sizing: border-box;
}

.checkbox {
  margin-left: 12px;
  background-color: transparent;
  border: 2px solid #6d44b8;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 2px;
  width: 20px;
  height: 20px;
}

.checkbox:checked {
  accent-color: #6d44b8;
  appearance: auto;
}

.errorRegDiv {
  color: red;
  margin: 0;
  padding: 12px;
  text-align: center;
}

.errorLogDiv {
  color: red;
  margin: 0;
  padding: 12px;
  text-align: center;
  font-weight: bolder;
}

.zaboravljenaLozinka>p {
  text-align: center;
  color: #573b8a;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}

.input {
  width: 65%;
  height: 20px;
  background: #e0dede;
  justify-content: center;
  display: flex;
  margin: 20px auto;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.btn {
  width: 65%;
  height: 40px;
  margin: 10px auto;
  justify-content: center;
  display: block;
  color: #fff;
  background: #573b8a;
  font-size: 1em;
  font-weight: bold;
  margin-top: 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease-in;
  cursor: pointer;
}

.btn:hover {
  background: #6d44b8;
}

.login {
  height: 650px;
  background: #eee;
  border-radius: 60% / 10%;
  transform: translateY(-130px);
  transition: 0.8s ease-in-out;
}

.login label {
  color: #573b8a;
  transform: scale(0.6);
  padding-top: 15px;
  margin-top: 40px;
  margin-bottom: 50px;
}

.chk {
  display: none;
}

.chk:checked~.login {
  transform: translateY(-650px);
}

.chk:checked~.login label {
  transform: scale(1);
}

.chk:checked~.signup label {
  transform: scale(0.6);
}