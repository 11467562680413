.appContainer {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.imgCover {
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  pointer-events: none;
}

.btnsWrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.leftButton,
.rightButton {
  width: 100%;
  background-color: transparent;
}

.leftButton {
  height: 72%;
}

.rightButton{
  height: 28%;
}