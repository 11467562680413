.familyPageContainer {
  flex: 1;
  overflow: auto;
  padding: 2rem;
  padding-top: 0;
  font-weight: 900;
  position: relative;
  margin-top: 1rem;
  scrollbar-width: none;

}

.familyPageContainer * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Comic Sans', sans-serif;
  color: white;
  font-weight: 900;
}

.join {
  font-size: 1.2rem !important;
  margin-top: -.1rem !important;
}

.familyPageContainer h2,
.green {
  color: #47fd54;
}

.green {
  margin-top: 1rem !important;
  font-size: 1.2rem !important;
}

.familyPageContainer h2 {
  font-size: 1.5rem;
}

.familyPageContainer h3 {
  color: #ff0000;
  font-size: 1rem;
}

.familyPageContainer p {
  font-size: 0.9rem !important;
  margin: 0.5rem 0;
}

.descBtn {
  background-color: #203864;
  border-radius: 2rem;
  border: 4px solid black;
  text-align: center;
  flex: 1;
  margin: 0.5rem 0;
  padding: 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.mid {
  background-color: #8faadc;
}

.mid h2 {
  color: black;
}

.mid h3 {
  color: #8b0000;
}

.mid .join {
  color: #203864;
}

.mid .green {
  color: #375623;
}

.betkoPlusBtn {
  background-color: #fb5123;
  color: white;
  border: 4px solid black;
  width: 100%;
  border-radius: 1rem;
  padding: .5rem;
  font-size: 3rem;
  cursor: pointer;
}
