.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 335px;
  min-height: 300px;
  box-shadow: 5px 20px 50px #070044;
  border-radius: 8px;
  cursor: pointer;
  margin: 8px;
}
.container > img {
  width: 320px;
  padding: 12px;
}
.container > video {
  padding: 12px;
}
.container > .featureVideoDiv {
  padding: 12px !important;
}
.title {
  text-align: center;
  padding: 0 20px !important;
}
