.container {
  display: flex;
  /* width: 90%; */
  /* width: 100%; */
  max-width: 1800px;
  width: calc(100% - 100px);
  height: calc(100vh - 356px);
  justify-content: center;
  margin: 0 auto;
  overflow: hidden;
  background-color: white;

}

.container::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
