.mainCenter {
  width: 65%;
  padding-right: 20px;
  overflow: auto !important;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.25;
}

.content {
  padding: 15px;
}

.title {
  width: 100%;
  font-size: 26px;
  justify-content: center;
  background-color: #070044;
  color: red;
  border-radius: 15px;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.comment {
  width: 100%;
  font-size: 26px;
  justify-content: center;
  background-color: #070044;
  color: rgb(0, 235, 0);
  border-radius: 15px;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.firstRow {
  width: 100%;
  display: flex;
  padding: 10px;
}

.contentRow {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 26px;
  text-align: center;
  margin-top: 10px;
}

.textField {
  margin-top: 10px;
  width: 100%;
  font-size: 12px;
  justify-content: center;
  background-color: #070044;
  color: white;
  border-radius: 15px;
  display: flex;
  padding: 10px;
  box-sizing: border-box;
}

.featureImage {
  min-width: 337px;
  height: 268px;
  margin-right: 10px;
}

.marketingImageATag {
  margin-right: 10px;
  min-width: 337px;
  height: 268px;
}

.marketingImage {
  width: 100%;
  height: 100%;
}

.itemImage {
  margin: 0 10px 10px 0;
  border-radius: 10px;
  width: 65%;
  max-height: 400px;
}

.itemImageATag {
  margin: 0 10px 10px 0;
  border-radius: 10px;
  width: 100%;
  max-height: 400px;
}

.navigationRow {
  display: flex;
  justify-content: space-around;
}

.featureVideoDiv {
  max-width: 337px;
  max-height: 267px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.firstPage,
.lastPage,
.targetPage,
.pageBack,
.pageForward {
  border: none;
  background-color: transparent;
  color: lightgray;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  transition: color 0.15s ease;
  cursor: pointer;
}

.firstPage:hover,
.lastPage:hover,
.targetPage:hover,
.pageBack:hover,
.pageForward:hover {
  color: black;
}

.targetPageContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.firstPage,
.lastPage {
  opacity: 0;
  pointer-events: none;
}

.firstPage.show,
.lastPage.show {
  opacity: 1;
  pointer-events: all;
}

.targetPage.isActive {
  color: black;
}

.pageBack.disabled {
  pointer-events: none;
}

.pageForward.disabled {
  pointer-events: none;
}

.commentStats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.commentStats > :nth-child(3) {
  background-color: black;
  color: #9bfa20;
}

.commentStats > div {
  background-color: rgb(27, 99, 222);
  color: white;
  padding: 10px 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-sizing: border-box;
}

.commentStats > div > span {
  text-transform: uppercase;
  font-size: 14px;
  word-wrap: break-word;
  line-height: normal;
}

.notificationsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.notificationsContainer > :nth-child(2) {
  flex-direction: row-reverse;
}

.obavestenja {
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 10px 0px;
  flex: 1;
  height: 100%;
}

.obavestenja > label {
  font-size: 12px;
  display: flex;
  align-items: center;
  color: white;
  background-color: #00b0f0;
  border: 1px solid #009cd5;
  border-radius: 10px;
  text-transform: uppercase;
  text-align: center;
  flex: 1;
  min-height: 40px;
  padding: 5px 10px;
}

.obavestenja > div {
  display: block;
  height: 100%;
  min-width: 40px;
  border: 1px solid #00b0f0;
  border-radius: 5px;
}

.obavestenja > div > input {
  min-width: 40px;
  min-height: 40px;
  margin: 0;
  appearance: none;
  position: relative;
  box-sizing: border-box;
  padding: 0;
}

.obavestenja > div > input:checked::before {
  content: '\2713';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #00b0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.allCommentsLoaderWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.allCommentsLoaderWrapper div {
  align-items: flex-start !important;
  padding-top: 2rem;
}
