.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  /* height: 100vh; */
  font-size: 1.5em;
  overflow: auto;
  font-weight: bold;
  color: red;
  margin: 0 auto;
}

.container p {
  color: #0066cc;
}
.red {
  color: red;
}
.blue {
  color: #0066cc !important;
}
.green {
  color: green !important;
}
.container > p {
  padding: 15px;
  margin: 0 auto;
}
.container > h2,
.container > h1,
.container > h3,
.container > h4 {
  color: red;
  margin: 15px;
  text-align: center;
  /* margin: 0 auto; */
}

.buyBtn {
  display: flex;
  width: 95%;
  border: 4px solid black;
  border-radius: 1rem;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: #fb284e;
  box-sizing: border-box;
  height: max-content;
  padding: 0.1rem;
  justify-content: space-between;
  cursor: pointer;
}

.buyBtn span {
  display: block;
  font-family: Arial Black, sans-serif;
  font-size: 2rem;
  font-weight: 900;
}

.buyBtn .midTxt {
  font-size: 3rem;
  line-height: 2.5rem;
}

.buyBtn .starWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  background-color: black;
  border: 2px solid white;
  aspect-ratio: 1/1;
  overflow: visible;
  border-radius: 100%;
  margin: 16px;
}

.buyBtn .starWrapper img {
  transform: scale(1.65);
  aspect-ratio: 1/1;
  height: 100%;
  margin-bottom: 0.8rem;
}
