.shopHeader {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background-color: #0B0B25;
  z-index: 10;
  padding: .725rem;
}

.shopHeaderBtns {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.shopHeaderBtns button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-size: 0.7rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  flex: 1;
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: center;
}

.backToHomeBtn {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 0.7rem;
}

.headerHomeBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  border-radius: 1rem;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  padding: 0.2rem;
  width: 70px;
}

.headerHomeBtn svg {
  color: white;
  height: 35px;
  width: 35px;
}

.headerHomeBtn span {
  display: block;
  color: white;
  font-weight: 700;
  font-size: 0.7rem;
}

.shopHeaderMid {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  width: 100%;
}

.shopHeaderMid input {
  font-size: 1rem;
  outline: none;
  width: 0px;
  flex: 1;
  border-radius: 1rem;
  border: none;
}

.shopHeaderMid input::placeholder {
  text-align: center;
  padding: 0;
  margin: 0;
}

.shopNavBtns {
  display: flex;
  justify-content: space-between;
  white-space: normal;
}

.betkoHome.shopNavBtns { 
  justify-content: space-around;
}

.shopNavBtns .iconWrapper {
  display: flex;
  height: 60px;
  width: 60px;
}

.iconWrapper svg {
  padding: 0.3rem;
}

.shopNavBtns button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 130px;
  color: white;
  background-color: transparent;
  border: none;
  font-size: 0.8rem;
  text-wrap: wrap;
  cursor: pointer;
  opacity: .6;

}

.selectedMenuItem {
  opacity: 1 !important;
}

.shopNavBtns .shopBtn {
  color: #ff0000;
}
.shopNavBtns .auctionsBtn {
  color: #ffc000;
}
.shopNavBtns .sportTuristBtn {
  color: #38f81e;
}

.caretsWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  height: 60px;
}

.caretDown,
.caretUp {
  color: red;
  height: 40px;
  width: 40px;
  transform: scaleY(2.5);
}

.caretUp {
  color: #b2ec0a;
  margin-left: -1rem;
}

@media (min-width: 765px) {
  .shopHeader {
    border-radius: 4.25rem ;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .shopHeaderBtns button {
    font-size: 1.1rem;
  }
  .backToHomeBtn {
    display: none;
  }
}
