.mainCenter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 65%;
  height: 100%;
  text-align: left;
  overflow: auto;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    align-items: center;
    margin: 0;
    gap: 10px;
  }
}

.left {
  font-size: 64px;
  padding: 20px;
  border: 6px solid #1b63de;
  border-radius: 12px;
}

.right {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.single {
  font-weight: 700;
  padding: 6px;
}

.single > span {
  color: #1b63de;
  margin-left: 12px;
  font-weight: 900;
}

.singleLastChild {
  font-weight: 700;
  padding: 6px;
  color: red;
  cursor: pointer;
}

.becomeTipsterBtn {
  background-color: #0914f1;
  color: white;
  padding: 2rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  max-width: 70%;
  gap: 2rem;
  cursor: pointer;
}

.becomeTipsterBtn svg {
  height: 3rem;
  width: 3rem;
  flex-shrink: 0;
}

.btn {
  width: 120px;
  padding: 16px 18px;
  background: #1b63de;
  color: #fff;
  border: none;
  border-radius: 8px;
  align-self: flex-end;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}

.admin {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 12px;
  border: 2px solid blueviolet;
  border-radius: 12px;
}

.admin > a {
  font-size: 24px;
  text-decoration: none;
  color: blueviolet;
  font-weight: 900;
}

.chooseTeam,
.changeUsername {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;
}

.chooseTeam > div > span {
  font-size: 24px;
}

.selectContainer,
.userNameContainer {
  flex: 1;
}

.selectContainer {
  text-align: center;
}

.selectContainer > span {
  font-size: 20px;
}

.selectField {
  width: 100%;
  min-height: 24px;
  border-radius: 4px;
  outline: none;
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
}

.selectField > option {
  font-weight: bold;
}

.selectField > :nth-child(1) {
  color: gray;
  font-weight: normal;
}

.userNameField {
  outline: none;
  border: none;
  border-bottom: 1px solid black;
  padding: 0px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
  color: #1b63de;
  margin: 0;
}

.changeTeamButton {
  color: red;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  cursor: pointer;
  font-family: 'Questrial', sans-serif;
}

.profileImageContainer {
  width: 200px;
  height: 250px;
  overflow: hidden;
}

.profileImageContainer > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.adminButtons {
  display: flex;
   gap: 20px;
}
